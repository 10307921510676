<template>
  <div class="flex-nowrap row justify-content-start bg-white">
    <div class="w-100 p-0" :style="{ height: `100dvh`, maxWidth: `500px` }">
      <div class="position-relative bg-white h-100 py-10">
        <div class="d-flex justify-content-center align-items-center h-100">
          <div class="w-75">
            <div class="p-3 position-relative d-flex">
              <NuxtImg id="logo" :src="runtimeConfig.public.logo" alt="GOP Variete Theater" />
            </div>
            <Card padding="p-0" shadow="shadow-none">
              <div class="p-4">
                <form role="form" class="text-start">
                  <Headline class="bg-white mb-0" :style="{ borderTopRightRadius: `16px` }">
                    Willkommen zurück
                  </Headline>
                  <div class="mb-2">
                    <Input v-model="email" type="email" placeholder="Bitte eingeben" label="Email" />
                  </div>
                  <div class="mb-2">
                    <Input v-model="password" type="password" placeholder="Bitte eingeben" label="Passwort" />
                  </div>
                  <div class="mb-2 f-small">
                    <NuxtLink to="/password-reset">Passwort vergessen?</NuxtLink>
                  </div>
                  <Button @click.prevent="login" type="submit" class="w-100" icon="material-symbols-light:login-sharp">
                    Jetzt einloggen
                  </Button>
                </form>
              </div>
            </Card>
            <Modal ref="authModal" size="small" title="Bestätigungscode">
              <div class="row">
                <div class="col-12">
                  Es wurde ein Bestätigungscode an Ihre Email Adresse gesendet.
                </div>
                <div class="col-12 mt-2">
                  <div class="d-flex gap-2">
                    <VOtpInput
                      @on-complete="auth"
                      v-model:value="emailToken"
                      input-classes="form-control"
                      separator=""
                      input-type="letter-numeric"
                      :num-inputs="6"
                      :placeholder="['*', '*', '*', '*', '*', '*']"
                      :should-auto-focus="true"
                      :should-focus-order="true"
                    />
                  </div>
                </div>
              </div>
              <ModalFooter>
                <Button html-type="primary" icon="material-symbols-light:check">Bestätigen</Button>
              </ModalFooter>
            </Modal>
          </div>
        </div>
      </div>
    </div>
    <div class="p-0 bg-white d-flex justify-content-center" :style="{ height: `100dvh`, maxWidth: `1000px`, minWidth: `1000px` }">
      <div class="d-grid grid-cols-3 p-4 gap-4 w-100 " :style="{ height: `100dvh` }">
        <div class="coloured-overlay-box border-radius-round h-100 position-relative d-flex justify-content-start align-items-end" :style="{ backgroundPosition: `20% 4%`, backgroundSize: `1388px`, backgroundRepeat: `no-repeat`, backgroundImage: `url(${background})` }" />
        <div class="coloured-overlay-box border-radius-round h-100 position-relative d-flex justify-content-start align-items-end" :style="{ backgroundPosition: `center 4%`, backgroundSize: `1388px`, backgroundRepeat: `no-repeat`, backgroundImage: `url(${background})` }" />
        <div class="coloured-overlay-box border-radius-round h-100 position-relative d-flex justify-content-start align-items-end" :style="{ backgroundPosition: `80% 4%`, backgroundSize: `1388px`, backgroundRepeat: `no-repeat`, backgroundImage: `url(${background})` }" />
        <div class="coloured-overlay-box border-radius-round h-100 position-relative d-flex justify-content-start align-items-end" :style="{ backgroundPosition: `20% center`, backgroundRepeat: `no-repeat`, backgroundSize: `1388px`, backgroundImage: `url(${background})` }" />
        <div class="coloured-overlay-box border-radius-round h-100 position-relative d-flex justify-content-start align-items-end" :style="{ backgroundPosition: `center center`, backgroundRepeat: `no-repeat`, backgroundSize: `1388px`, backgroundImage: `url(${background})` }" />
        <div class="coloured-overlay-box border-radius-round h-100 position-relative d-flex justify-content-start align-items-end" :style="{ backgroundPosition: `80% center`, backgroundRepeat: `no-repeat`, backgroundSize: `1388px`, backgroundImage: `url(${background})` }" />
        <div class="position-relative coloured-overlay-box border-radius-round h-100 position-relative d-flex justify-content-start align-items-end" :style="{ backgroundPosition: `20% 96%`, backgroundRepeat: `no-repeat`, backgroundImage: `url(${background})` }">
          <div class="position-absolute bottom-0 p-4 end-0">
            <div class="text-end">
              <span class="text-white fw-semibold">by</span>
              <a href="https://tixu.io" target="_blank" title="Besuchen Sie tixu.io" class="position-relative d-flex">
                <NuxtImg src="/images/tixu-logo-white.png" alt="Tixu" width="80px" />
              </a>
            </div>
          </div>
        </div>
        <div class="coloured-overlay-box border-radius-round h-100 position-relative d-flex justify-content-start align-items-end" :style="{ backgroundPosition: `center 96%`, backgroundSize: `1388px`, backgroundRepeat: `no-repeat`, backgroundImage: `url(${background})` }" />
        <div class="coloured-overlay-box border-radius-round h-100 position-relative d-flex justify-content-start align-items-end" :style="{ backgroundPosition: `80% 96%`, backgroundSize: `1388px`, backgroundRepeat: `no-repeat`, backgroundImage: `url(${background})` }" />
      </div>
    </div>
  </div>
</template>

<script setup>
import VOtpInput from "vue3-otp-input";

definePageMeta({ layout: "variete", title: "Login" });

const authModal = ref({});
const emailToken = ref("");
const { signIn } = useAuth();
const email = ref("");
const password = ref("");
const { toast } = useNuxtApp();

// Cookie reset
const shop = useCookie("shop");
const client = useCookie("client");
shop.value = null;
client.value = null;

const { runtimeConfig } = useNuxtApp();
const background = runtimeConfig.public.loginBackground;

async function login() {
  try {
    await signIn({ email: email.value, password: password.value }, { callbackUrl: "/", external: true });
  } catch (e) {
    if (e.response) toast.showError("Login", e.response?._data.info);
    else toast.showError("Login", e);
  }
}

// async function auth() {
//   // if (emailToken.value && loginToken.value) {
//   globalStore.isLoading = true;
//   const data = await $fetch(`${runtimeConfig.public.apiURL}/admins/auth-mail`, {
//     method: "POST",
//     body: { login_token: loginToken.value, email_token: emailToken.value },
//   });
//
//   if (data.success) {
//     const adminToken = useCookie("admin_token");
//     const client = useCookie("client");
//     const shop = useCookie("shop");
//
//     adminToken.value = data.admin.access_token;
//     if (data.admin.client_id) client.value = data.admin.client_id;
//     if (data.admin.shop_id) shop.value = data.admin.shop_id;
//
//     location.href = "/";
//   } else toast.showError("Login", data.info);
//
//   globalStore.isLoading = false;
//   // }
// }
</script>
